.container {
  background: #eee;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  margin-top: 10px;
  border: 2px solid #bbb;
  color: #666360;

  display: flex;
  align-items: center;
}
.container:focus {
  color: #188cbf;
  border-color: #188cbf;
}

.input {
  flex: 1;
  background: transparent;
  border: 0;
  color: #666360;
  width: 100%;
  padding-left: 10px;
}

.svg {
  margin-right: 16px;
  min-width: 10%;
}

.error {
  border-color: #c53030;
}
