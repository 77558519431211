.info {
  margin: 0;
  padding: 0;
}

.title {
  text-align: center;
  padding-right: 6rem;
  color: #666360;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.row span {
  margin-right: 10px;
  background-color: linear-gradient(60deg, #26c6da, #00acc1);
  font-size: 20px;
  font-weight: bold;
}
