.editor {
  max-width: 1000px;
  height: 100%;
}
.editorWrapper {
  min-width: 1000px;
  width: 100%;
}
.gradeWrapper {
  /* width: 1000px; */
  width: 100%;
  width: 1000px;
}

.fileInput {
  color: #fff;
  border: none;
  cursor: pointer;
  margin: 0.3125rem 1px;
  padding: 12px 30px;
  position: relative;
  font-size: 12px;
  min-width: auto;
  max-width: 300px;
  box-shadow: 0 2px 2px 0 rgb(153 153 153 / 14%),
    0 3px 1px -2px rgb(153 153 153 / 20%), 0 1px 5px 0 rgb(153 153 153 / 12%);
  min-height: auto;
  text-align: center;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 400;
  line-height: 1.42857143;
  white-space: nowrap;
  will-change: box-shadow, transform;
  touch-action: manipulation;
  border-radius: 3px;
  letter-spacing: 0;
  text-transform: uppercase;
  vertical-align: middle;
  background-color: #999;
}
/* 
input {
  text-align: right;
  font-weight: 200;
  text-size-adjust: 200;
} */

.cardInfo {
  display: flex;
  justify-content: flex-end;
}

.cardTitle {
  color: #3c4858;
  text-decoration: none;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 3px;
  min-height: auto;
}

.cardCategory {
  color: #999999;
  margin: 0;
}

.field {
  min-width: 300px !important;
  width: 100%;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.essayPreview {
  margin-top: 1rem;
  max-width: 100%;
  margin-bottom: 1rem;
}

@media screen and (min-width: 1100px) {
  .editor {
    width: calc(100vw);
  }
}
