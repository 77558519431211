.container {
  height: 100%;
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: #fff;
  background-position: 0px -25vh;
  color: #444;

  padding-top: 50px;
}
